<div class="main-body">
  <!-- company details -->
  <ng-container *ngIf="isCompanyAvailable && !isLoaded">
    <!-- profile -->
    <div class="custom-card">
      <div class="profile-card">
        <div class="saved-tag"
             *ngIf="globalHelper.isSaved.isCmpSaved"
        >
          <img class="vts-svg-green"
               src="assets/images/svg/saved-tag.svg"
               alt="saved"
          >
        </div>
        <div class="profile-top">
          <div class="compLogo">
            <img [src]="this.globalHelper.pDetails.cmpLogo ? this.globalHelper.pDetails.cmpLogo :
                this.linkedInScrappedData.contact.experience[0].companyLogoUrl"
                 alt="companyLogo"
            >
          </div>
          <div class="profile-content">
            <div class="cnt-desc">
              <div class="name">
                <h6 #companyName
                    class="overflow-text"
                    [tooltip]="companyName.offsetWidth < companyName.scrollWidth ? globalHelper.pDetails.company_name :''"
                    placement="bottom"
                >
                  {{ globalHelper.pDetails.company_name }}
                </h6>
              </div>
              <div class="action">
                <div class="redirect">
                  <img class="vts-svg-primary"
                       src="assets/images/svg/redirect.svg"
                       alt="redirect"
                       (click)="globalHelper.goToDetails(globalHelper.pDetails.companyId,'company')"
                  >
                </div>
                <div class="addList">
                  <img class="vts-svg-primary cursor"
                       [src]="!globalHelper.isSaved.isCmpSaved ? 'assets/images/svg/addList.svg' :
                          'assets/images/svg/addList-fill.svg'"
                       alt="addList"
                       (click)="addToCmpList()"
                       [tooltip]="ls.data.company.toolTip.addToList"
                       placement="top"
                  >
                  <div class="vts-border-theme save-contact"
                       *ngIf="isCmpListAdded"
                  >
                    <div class="form-group">
                      <ng-select #cmpListSelect
                                 [items]="globalHelper.cmpListData.cmpListDataAvl"
                                 bindLabel="value"
                                 bindValue="value"
                                 [addTag]="globalHelper.addList"
                                 [addTagText]="ls.data.company.createNewList"
                                 [multiple]="true" [placeholder]="ls.data.company.placeholder.searchCreateNewList"
                                 [(ngModel)]="globalHelper.selectedListType.selectedCmpList"
                                 (change)="globalHelper.onListChange('company')"
                      >
                      </ng-select>
                    </div>
                    <div class="actionButtons">
                      <span class="vts-theme-bg vts-border-theme fill-btn"
                            (click)="saveCompanyList(globalHelper.selectedListType.selectedCmpList)"
                      >
                        {{ ls.data.global.buttons.save }}
                      </span>
                      <span class="vts-border-theme vts-icon-color"
                            (click)="onListCancel('company')"
                      >
                        {{ ls.data.global.buttons.cancel }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p #parentDept class="cmpDesc"
               [tooltip]="parentDept.offsetWidth < parentDept.scrollWidth ? globalHelper.pDetails.parentDept :''"
               placement="bottom"
            >
              {{
                globalHelper.pDetails.parentDept ? globalHelper.pDetails.parentDept :
                  ls.data.global.misc.notAvailable
              }}
            </p>
          </div>
        </div>
        <div class="profile-bottom">
          <p class="cmpDesc cmpDetails"
             *ngIf="globalHelper.pDetails.company_description"
          >
            {{
              globalHelper.isExpanded.open ? globalHelper.pDetails.company_description :
                globalHelper.isExpanded.open ? globalHelper.pDetails.company_description :
                  globalHelper.pDetails.company_description.slice(0, 100)
            }}
            <a class="vts-icon-color textToExpand"
               (click)="globalHelper.toggleDescription()"
               *ngIf="globalHelper.pDetails.company_description !== '' && globalHelper.pDetails.company_description.length > 100"
            >
              {{
                globalHelper.isExpanded.open ? ls.data.global.misc.viewLess : ls.data.global.misc.viewMore
              }}
            </a>
          </p>
        </div>
      </div>
    </div>
    <!-- description -->
    <div class="custom-card">
      <div class="profile-card">
        <div class="cmpProfile-bottom">
          <div class="vts-tag-color panel-title">
            {{ ls.data.company.companyInfo }}
          </div>
          <div class="viewComDetails">
            <div class="cmp-details-list">
              <div class="cmp-list-item">
                <div class="infographics ">
                  <img class="vts-svg-secondary"
                       src="assets/images/svg/copy.svg"
                       alt="about"
                  >
                </div>
                <div class="infoDesc">
                  <span class="vts-icon-color cmp-name"
                        (click)="goToExternalLink(globalHelper.pDetails.website,false)"
                  >
                    {{ globalHelper.pDetails.website }}
                  </span>
                  <span *ngIf="globalHelper.pDetails.company_li_url">
                    |
                    <img src="assets/images/svg/linkedin.svg"
                         alt="linkedin"
                         (click)="goToExternalLink(globalHelper.pDetails.company_li_url,false)"
                    >
                  </span>
                  <span *ngIf="globalHelper.pDetails.company_facebook_url">
                    |
                    <img src="assets/images/svg/facebook.svg"
                         alt="facebook"
                         (click)="goToExternalLink(globalHelper.pDetails.company_facebook_url,false)"
                    >
                  </span>
                </div>
              </div>
              <div class="cmp-list-item">
                <div class="infographics">
                  <img class="vts-svg-secondary"
                       src="assets/images/svg/founded.svg"
                       alt="founded"
                  >
                </div>
                <div class="vts-tag-color infoDesc">
                  <span>
                    {{ ls.data.company.foundIn }}
                  </span>
                  <p class="valCount">
                    {{ globalHelper.pDetails.founded }}
                  </p>
                </div>
              </div>
              <div class="cmp-list-item">
                <div class="infographics">
                  <img class="vts-svg-secondary"
                       src="assets/images/svg/headquarter.svg"
                       alt="headquarter"
                  >
                </div>
                <div class="vts-tag-color infoDesc">
                  <span>
                    {{ ls.data.company.headquarterLocation }}
                  </span>
                  <p class="valCount">
                    {{
                      globalHelper.pDetails.hq_location ? globalHelper.pDetails.hq_location :
                        ls.data.global.misc.notAvailable
                    }}
                  </p>
                </div>
              </div>
              <div class="cmp-list-item">
                <div class="infographics">
                  <img class="vts-svg-secondary"
                       src="assets/images/svg/empSize.svg"
                       alt="empSize"
                  >
                </div>
                <div class="vts-tag-color infoDesc">
                  <span>
                    {{ ls.data.company.employeeSize }}
                  </span>
                  <p class="valCount">
                    {{
                      globalHelper.pDetails.emp_range ? globalHelper.pDetails.emp_range :
                        ls.data.global.misc.notAvailable
                    }}
                  </p>
                </div>
              </div>
              <div class="cmp-list-item">
                <div class="infographics">
                  <img class="vts-svg-secondary"
                       src="assets/images/svg/revenue.svg"
                       alt="revenue"
                  >
                </div>
                <div class="vts-tag-color infoDesc">
                  <span>
                    {{ ls.data.company.annualRevenueRange }}
                  </span>
                  <p class="valCount">
                    {{
                      globalHelper.pDetails.rev_range ? globalHelper.pDetails.rev_range :
                        ls.data.global.misc.notAvailable
                    }}
                  </p>
                </div>
              </div>
              <div class="cmp-list-item">
                <div class="infographics">
                  <img class="vts-svg-secondary"
                       src="assets/images/svg/industry.svg"
                       alt="industry"
                  >
                </div>
                <div class="vts-tag-color infoDesc">
                  <span>
                    {{ ls.data.company.industry }}
                  </span>
                  <p class="valCount">
                    {{
                      globalHelper.pDetails.parentDept ? globalHelper.pDetails.parentDept :
                        ls.data.global.misc.notAvailable
                    }}
                  </p>
                </div>
              </div>
              <div class="cmp-list-item">
                <div class="infographics">
                  <img class="vts-svg-secondary"
                       src="assets/images/svg/specialities.svg"
                       alt="specialities"
                  >
                </div>
                <div class="vts-tag-color infoDesc">
                  <span>
                    {{ ls.data.company.specialities }}
                  </span>
                  <div class="pChipSets">
                    <ng-container *ngIf="globalHelper.pDetails.specialty.length">
                      <div class="vts-chipset pChipSet"
                           *ngFor="let item of globalHelper.pDetails.specialty"
                      >
                        {{ item }}
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!globalHelper.pDetails.specialty.length">
                      <p class="valCount">
                        {{ ls.data.global.misc.notAvailable }}
                      </p>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- key employees -->
    <div class="custom-card mb-0">
      <div class="profile-card"
           *ngIf="globalHelper.employeeData.cntData && globalHelper.employeeData.cntData.length > 0"
      >
        <div class="empProfile-bottom">
          <div class="card-title">
            <div class="vts-tag-color panel-title">
              {{ ls.data.company.keyEmployees }}
            </div>
            <div class="vts-icon-color link"
                 (click)="appComponent.toggleFill('employee', true)"
            >
              {{ ls.data.company.showEmployees }}
            </div>
          </div>
          <div class="viewEmpDetails">
            <div class="emp-details-list">
              <div *ngFor="let employee of globalHelper.employeeData.cntData;let i = index"
                   class="emp-list-item"
              >
                <div class="emp-list-top">
                  <div class="emp-list-top-left">
                    <div class="employee-logo">
                      <ng-container *ngIf="employee.imageUrl; else initials">
                        <img [src]="employee.imageUrl"
                             alt="company-logo"
                        >
                      </ng-container>
                      <ng-template #initials>
                        <div class="initials"
                             [ngStyle]="employee.avatarStyle"
                        >
                          {{ globalHelper.getInitials(employee.fullName) }}
                        </div>
                      </ng-template>
                    </div>
                    <div class="employee-info">
                      <div class="employee-title">
                        <h4 #firstName
                            class="vts-icon-color overflow-text"
                            [tooltip]="firstName.offsetWidth < firstName.scrollWidth? employee.fullName :''"
                            placement="bottom"
                            (click)="globalHelper.goToDetails(employee.contactId,'contact')"
                        >
                          {{ employee.fullName }}
                        </h4>
                        <ng-container *ngIf="employee?.contact_li_url">
                          <img class="social_icon" src="assets/images/svg/linkedin.svg"
                               alt="linkedin"
                               (click)="goToExternalLink(employee.contact_li_url,false)"
                          >
                        </ng-container>
                        <ng-container *ngIf="employee?.facebook_url">
                          <img class="social_icon"
                               src="assets/images/svg/facebook.svg"
                               alt="facebook"
                               (click)="goToExternalLink(employee.facebook_url,false)"
                          >
                        </ng-container>
                      </div>
                      <p #jobTitleEle
                         class="overflow-text"
                         [tooltip]="jobTitleEle.offsetWidth < jobTitleEle.scrollWidth ? employee.jobTitle :''"
                         placement="bottom"
                      >
                        {{ employee.jobTitle ? employee.jobTitle : "" }}
                      </p>
                    </div>
                  </div>
                  <div class="emp-list-top-right">
                    <div class="action">
                      <div class="addList">
                        <img class="vts-svg-primary cursor"
                             [src]="!employee.alreadySaved ? 'assets/images/svg/addList.svg' : 'assets/images/svg/addList-fill.svg'"
                             alt="addList"
                             (click)="addToCntList(employee.contactId,i,employee.companyId)"
                             [tooltip]="ls.data.company.toolTip.addToList"
                             placement="top"
                        >
                        <div class="vts-border-theme save-contact"
                             *ngIf="isCntListAdded[i]"
                        >
                          <div class="form-group">
                            <ng-select #cntListSelect
                                       [items]="globalHelper.cntListData.cntListDataAvl"
                                       bindLabel="value"
                                       bindValue="value"
                                       [addTag]="globalHelper.addList"
                                       [addTagText]="ls.data.company.createNewList" [multiple]="true"
                                       [placeholder]="ls.data.company.placeholder.searchCreateNewList"
                                       [(ngModel)]="globalHelper.selectedListType.selectedCntList"
                                       (change)="globalHelper.onListChange('contact')"
                            ></ng-select>
                          </div>
                          <div class="actionButtons">
                            <span class="vts-theme-bg vts-border-theme fill-btn"
                                  (click)="saveContactList(globalHelper.selectedListType.selectedCntList,i,employee.contactId,employee.companyId)"
                            >
                              {{ ls.data.global.buttons.save }}
                            </span>
                            <span class="vts-border-theme vts-icon-color"
                                  (click)="onListCancel('contact',i)"
                            >
                              {{ ls.data.global.buttons.cancel }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="emp-list-bottom">
                  <div class="emp-list-bottom-left">
                    <div class="vts-green-color-border empDetails">
                      <div class="contact-details"
                           [ngClass]="{
                            'green-contact': employee.alreadyView && (employee.emailAddress || employee.phoneOffice || employee.phoneDirect),
                            'not-available': !employee.emailAddress && !employee.phoneOffice && !employee.phoneDirect,
                            'disabled': employee.isContactCollapse
                         }"
                           (click)="viewContact(employee.contactId,employee.isContactCollapse)"
                           #item
                           (mouseenter)="globalHelper.showHover(item)"
                           (mouseleave)="globalHelper.hideHover(item)"
                      >
                        <div class="details-wrapper">
                          <img src="assets/images/svg/xd-email.svg"
                               alt="mail"
                               class="vts-svg-primary"
                               placement="top"
                               [tooltip]="employee.emailAddress || employee.phoneOffice || employee.phoneDirect ?
                                    ls.data.company.workEmail : ''"
                               [ngClass]="{
                                     'item-not-available': !employee.emailAddress,
                                     'item-green': employee.alreadyView && employee.emailAddress
                                 }"
                          >
                          <span class="divider">|</span>
                          <img src="assets/images/svg/xd-office-phone.svg"
                               alt="mail"
                               class="vts-svg-primary"
                               placement="top"
                               [tooltip]="employee.emailAddress || employee.phoneOffice || employee.phoneDirect ?
                                   ls.data.company.phoneOffice : ''"
                               [ngClass]="{
                                     'item-not-available': !employee.phoneOffice,
                                     'item-green': employee.alreadyView && employee.phoneOffice
                                 }"
                          >
                          <span class="divider">|</span>
                          <img src="assets/images/svg/xd-cell-phone.svg"
                               alt="mail"
                               class="vts-svg-primary"
                               placement="top"
                               [tooltip]="employee.emailAddress || employee.phoneOffice || employee.phoneDirect ?
                                    ls.data.company.directPhone : ''"
                               [ngClass]="{
                                 'item-not-available': !employee.phoneDirect,
                                 'item-green': employee.alreadyView && employee.phoneDirect
                                 }"
                          >
                        </div>

                        <div *ngIf="globalHelper.hoveredItem === item &&
                                    (employee.emailAddress || employee.phoneOffice || employee.phoneDirect) &&
                                    !employee.alreadyView"
                             class="show-details"
                             [tooltip]="!employee.alreadyView ? ls.data.global.toolTip.thisCostOneCredit : ''"
                             placement="right"
                        >
                          <p>{{ ls.data.actions.showDetails }}</p>
                        </div>
                      </div>
                      <div class="hide-link"
                           *ngIf="employee.isContactCollapse"
                           (click)="viewContact(employee.contactId,employee.isContactCollapse)"
                      >
                        {{ ls.data.actions.hide }}
                      </div>
                    </div>
                    <div class="empDetailsView"
                         *ngIf="employee.isContactCollapse && contactData"
                    >
                      <ul>
                        <li [ngClass]="{'na':!contactData.emailAddress}">
                          <div class="label">
                            {{ ls.data.company.workEmail }}
                          </div>
                          <div #emailEle
                               class="value"
                               [tooltip]="emailEle.offsetWidth < emailEle.scrollWidth ? contactData.emailAddress :''"
                               placement="top"
                          >
                            :
                            {{
                              contactData.emailAddress ? contactData.emailAddress :
                                ls.data.global.misc.notAvailable
                            }}
                          </div>
                        </li>
                        <li [ngClass]="{'na':!contactData.phoneOffice}">
                          <div class="label">
                            {{ ls.data.company.phoneOffice }}
                          </div>
                          <div class="value">
                            :
                            {{
                              contactData.phoneOffice ? contactData.phoneOffice :
                                ls.data.global.misc.notAvailable
                            }}
                          </div>
                        </li>
                        <li [ngClass]="{'na':!contactData.directPhone}">
                          <div class="label">
                            {{ ls.data.company.directPhone }}
                          </div>
                          <div class="value">
                            :
                            {{
                              contactData.phoneDirect ? contactData.phoneDirect :
                                ls.data.global.misc.notAvailable
                            }}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="redirectEmp text-center">
        <div class="redirect-pointer"
             (click)="globalHelper.goToDetails(globalHelper.employeeData.cntData[0].companyId,'contact')"
        >
          <strong>
            {{ ls.data.company.moreCompanyDetails }}
          </strong>
          <img class="vts-svg-primary"
               src="assets/images/svg/redirect.svg"
               alt="redirect"
          >
        </div>
      </div>
    </div>
  </ng-container>

  <!-- company not found -->
  <ng-container *ngIf="!isCompanyAvailable && !isLoaded">
    <div class="custom-card mb-0 customHeight">
      <div class="profile-card">
        <div class="profile-top cmpProfileTop">
          <div class="userLogo cmpLogo">
            <img
              [src]="linkedInScrappedData.company.companyLogoUrl ? linkedInScrappedData.company.companyLogoUrl :
                linkedInScrappedData.contact.experience[0].companyLogoUrl ? linkedInScrappedData.contact.experience[0].companyLogoUrl:'assets/images/png/defaultCompany.png'"
              alt="companyLogo"
            >
          </div>
          <div class="profile-content">
            <div class="cnt-desc">
              <div class="name">
                <h6 #CmpName
                    class="overflow-text"
                    [tooltip]="CmpName.offsetWidth < CmpName.scrollWidth ? linkedInScrappedData.company.companyName ||
                      linkedInScrappedData.contact.experience[0].companyName :''"
                    placement="bottom"
                >
                  {{ linkedInScrappedData.company.companyName || linkedInScrappedData.contact.experience[0].companyName }}
                </h6>
              </div>
              <div class="action no-cursor">
                <div class="redirect">
                  <img class="vts-svg-primary"
                       src="assets/images/svg/redirect.svg"
                       alt="redirect"
                  >
                </div>
                <div class="addList">
                  <img class="vts-svg-primary"
                       src="assets/images/svg/addList.svg"
                       alt="addList"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cmpProfile-bottom">
          <div class="vts-border-theme notFound text-center">
            <img class="vts-svg-primary"
                 src="assets/images/svg/not-found.svg"
                 alt="not-found"
            >
            <p>
              {{ ls.data.company.noCompanyDataFoundYetWe }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- skeleton -->
  <ng-container *ngIf="isLoaded">
    <div class="container">
      <div class="row">
        <div class="skeleton">
          <div class="custom-skeleton">
            <div class="p-d-flex p-mb-3">
              <div class="circle p-mr-2 skeletonEffect"
                   size="4rem"
              ></div>
              <div class="stripes">
                <div class="p-mb-2 skeletonEffect"></div>
                <div class="p-mb-2 skeletonEffect"></div>
              </div>
            </div>
            <div class="stripes fullStripes">
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
            </div>
          </div>
          <div class="custom-skeleton">
            <div class="stripes fullStripes">
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
