<div class="main-body settings"
     *ngIf="!settingsLoader"
>
  <!--  user profile -->
  <div class="custom-card">
    <div class="profile-card">
      <div class="profile-desc">
        <div class="avtar">
          <div class="userLogo">
            <div class="initials"
                 [ngStyle]="avatarStyle"
            >
              {{ globalHelper.getInitials(globalHelper.userProfile.firstName + ' ' + globalHelper.userProfile.lastName) }}
            </div>
          </div>
        </div>
        <div class="profile-details">
          <h6 class="overflow-text user-name">
            {{ globalHelper.userProfile.firstName + ' ' + globalHelper.userProfile.lastName }}
          </h6>
          <div class="plan">
            <div class="your-plan">
              {{ globalHelper.subscription.tierName | uppercase }}
            </div>
            <div class="vts-icon-color upgrade"
                 (click)="redirectToApp('upgrade')"
                 *ngIf="globalHelper.subscription.tierName!=='Enterprise'"
            >
              {{ ls.data.global.misc.upgrade }}
            </div>
          </div>
          <div class="edit"
               [tooltip]="ls.data.global.toolTip.editProfile"
          >
            <span class="vts-icon-color"
                  (click)="redirectToApp('edit-profile')"
            >
              {{ ls.data.global.buttons.edit }}
            </span>
          </div>
          <div class="credits d-none"
               *ngIf="globalHelper.chExtCredits"
          >
            <div class="vts-icon-color credit-label">
              {{ ls.data.settings.credits }}
            </div>
            <div class="custom-progress-bar">
              <div class="vts-icon-color stages">
                {{ globalHelper.chExtCredits.usedChromeExtCredits }} / {{ globalHelper.chExtCredits.chExtCredits }}
              </div>
              <div class="progress-bar-container">
                <div class="progress-bar"
                     [style.width.%]="globalHelper.chExtCredits.progress"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- predictiv platform -->
  <div class="custom-card">
    <div class="card-top cardHeadBorder">
      <h6 class="vts-tag-color cardHead">
        {{ ls.data.settings.predictivPlatform }}
      </h6>
    </div>
    <div class="card-bottom">
      <div class="redirectActions">
        <div *ngFor="let action of redirectActions; let i = index"
             class="redirectAction">
          <div class="redirectActionLeft">
            <div class="ico {{'ico'+i}}">
              <img class="vts-svg-primary"
                   [ngClass]="'ico'+i"
                   [src]="action.imgUrl"
                   alt="redirect"
              >
            </div>
            <div class="redirectActionTitle">
              <h6 class="pointer-cursor"
                  (click)="redirectToApp(action.url)"
              >
                {{ action.title }}
              </h6>
            </div>
          </div>
          <div class="redirectActionRight">
            <div class="ico redirect"
                 (click)="redirectToApp(action.url)"
            >
              <img class="vts-svg-primary"
                   [src]="'assets/images/svg/redirect.svg'"
                   [alt]="action.title"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- extension preferences -->
  <div class="custom-card">
    <div class="card-top cardHeadBorder">
      <h6 class="vts-tag-color cardHead">
        {{ ls.data.settings.extensionPreferences }}
      </h6>
    </div>
    <div class="card-bottom">
      <div class="redirectActions">
        <div class="redirectAction">
          <div class="redirectActionLeft">
            <div class="ico preferences">
              <img class="vts-svg-primary"
                   [src]="'assets/images/svg/plugins.svg'"
                   alt="Plugin position"
              >
            </div>
            <div class="redirectActionTitle">
              <h6>
                {{ ls.data.settings.pluginPosition }}
              </h6>
            </div>
          </div>
          <div class="redirectActionRight">
            <div class="plugin-position">
              <img class="vts-svg-primary"
                   [src]="globalHelper.pluginSettings.position !== 'left' ? 'assets/images/svg/left.svg' :
                        'assets/images/svg/leftFil.svg' "
                   (click)="changePosition('left')"
                   alt="left"
              >
              <img class="vts-svg-primary"
                   [src]="globalHelper.pluginSettings.position !== 'right' ? 'assets/images/svg/right.svg':
                        'assets/images/svg/rightFill.svg'"
                   (click)="changePosition('right')"
                   alt="right"
              >
            </div>
          </div>
        </div>
        <div class="redirectAction">
          <div class="redirectActionLeft">
            <div class="ico preferences">
              <img class="vts-svg-primary"
                   [src]="'assets/images/svg/autoOpen.svg'"
                   alt="Auto open"
              >
            </div>
            <div class="redirectActionTitle">
              <h6>
                {{ ls.data.settings.autoOpen }}
              </h6>
            </div>
          </div>
          <div class="redirectActionRight">
            <div class="toggle">
              <label class="switch">
                <input type="checkbox"
                       [checked]="globalHelper.pluginSettings.open"
                       (click)="autoOpen($event)"
                >
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="redirectAction d-none">
          <div class="redirectActionLeft">
            <div class="ico preferences">
              <img class="vts-svg-primary"
                   [src]="'assets/images/svg/plugins.svg'"
                   alt="Predictiv Everywhere"
              >
            </div>
            <div class="redirectActionTitle">
              <h6>
                {{ ls.data.settings.predictivEveryWhere }}
              </h6>
            </div>
          </div>
          <div class="redirectActionRight">
            <div class="toggle">
              <label class="switch">
                <input type="checkbox"
                       [checked]="globalHelper.pluginSettings.everywhere"
                       (click)="predictivEveryWhere($event)"
                >
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- default list -->
  <div class="custom-card">
    <div class="card-top cardHeadBorder">
      <h6 class="vts-tag-color cardHead">
        {{ ls.data.settings.defaultList }}
      </h6>
    </div>
    <div class="card-bottom">
      <div class="redirectActions">
        <!-- contact -->
        <div class="redirectAction ngSelect">
          <div class="redirectActionLeft">
            <div class="redirectActionTitle">
              <h6>{{ ls.data.activities.contact }}</h6>
            </div>
          </div>
          <div class="redirectActionRight ">
            <div class="action">
              <div class="vts-border-theme addList">
                <div class="form-group">
                  <ng-select [items]="globalHelper.defaultListData.cntListData"
                             bindLabel="label"
                             bindValue="label"
                             [addTag]="globalHelper.addList"
                             [addTagText]="ls.data.company.createNewList"
                             [multiple]="false" [placeholder]="ls.data.settings.defaultList"
                             [(ngModel)]="globalHelper.defaultList.defaultSelectedCntList"
                             (change)="onListChange($event,'contact')"
                             (clear)="globalHelper.getDefaultCntList()"
                  ></ng-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- company -->
        <div class="redirectAction ngSelect">
          <div class="redirectActionLeft">
            <div class="redirectActionTitle">
              <h6>
                {{ ls.data.activities.company }}
              </h6>
            </div>
          </div>
          <div class="redirectActionRight">
            <div class="action">
              <div class="vts-border-theme addList">
                <div class="form-group">
                  <ng-select [items]="globalHelper.defaultListData.cmpListData"
                             bindLabel="label"
                             bindValue="label"
                             [addTag]="globalHelper.addList"
                             [addTagText]="ls.data.company.createNewList"
                             [multiple]="false" [placeholder]="ls.data.settings.defaultList"
                             [(ngModel)]="globalHelper.defaultList.defaultSelectedCmpList"
                             (change)="onListChange($event,'company')"
                             (clear)="globalHelper.getDefaultCmpList()"
                  ></ng-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="redirectAction whereToSave">
          <div class="redirectActionLeft">
            <div class="redirectActionTitle">
              <h6>
                {{ ls.data.settings.askWhereToSaveList }}
              </h6>
            </div>
          </div>
          <div class="redirectActionRight">
            <div class="toggle">
              <label class="switch">
                <input type="checkbox"
                       [checked]="globalHelper.pluginSettings.askWhereToSave"
                       (click)="whereToSave($event)"
                >
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- themes -->
  <div class="custom-card">
    <div class="card-top cardHeadBorder">
      <h6 class="vts-tag-color cardHead">
        {{ ls.data.settings.themes }}
      </h6>
    </div>
    <div class="card-bottom">
      <div class="redirectActions">
        <div class="redirectAction">
          <div class="redirectActionLeft">
            <div class="themes">
              <div class="radio blue"
                   (click)="setTheme('blue-theme')"
                   [ngClass]="{'selectedTheme':globalHelper.pluginSettings.theme === 'blue-theme'}"
              >
              </div>
              <div class="radio light"
                   (click)="setTheme('light-theme')"
                   [ngClass]="{'selectedTheme':globalHelper.pluginSettings.theme === 'light-theme'}"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- logout -->
  <div class="custom-card logoutItem">
    <div class="card-bottom">
      <div class="redirectActions">
        <div class="redirectAction">
          <div class="redirectActionLeft">
            <div class="ico">
              <img class="vts-svg-primary icoSignOut"
                   [src]="'assets/images/svg/signOut.svg'"
                   alt="Sign out"
              >
            </div>
            <div class="redirectActionTitle">
              <h6 class="pointer-cursor"
                  (click)="openPopup()"
              >
                {{ ls.data.settings.signOut }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- modal -->
  <div class="popUp" *ngIf="confirmPopup">
    <div class="modal-content">
      <h6>
        {{ ls.data.settings.areYouSureYouWantSign }}
      </h6>
      <div class="actionBtn">
        <div class="vts-border-theme customBtn"
             (click)="cancelLogout()"
        >
          {{ ls.data.global.buttons.no }}
        </div>
        <div class="vts-theme-bg vts-border-theme customBtn fill"
             (click)="confirmLogout()"
        >
          {{ ls.data.global.buttons.yes }}
        </div>
      </div>
    </div>
  </div>
</div>
<!-- skeleton -->
<ng-container *ngIf="settingsLoader"
>
  <div class="container">
    <div class="row">
      <div class="skeleton">
        <div class="custom-skeleton">
          <div class="p-d-flex p-mb-3">
            <div class="circle p-mr-2 skeletonEffect"
                 size="4rem"
            ></div>
            <div class="stripes">
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
            </div>
          </div>
          <div class="stripes fullStripes">
            <div class="p-mb-2 skeletonEffect"></div>
            <div class="p-mb-2 skeletonEffect"></div>
            <div class="p-mb-2 skeletonEffect"></div>
            <div class="p-mb-2 skeletonEffect"></div>
            <div class="p-mb-2 skeletonEffect"></div>
            <div class="p-mb-2 skeletonEffect"></div>
            <div class="p-mb-2 skeletonEffect"></div>
          </div>
        </div>
        <div class="custom-skeleton">
          <div class="stripes fullStripes">
            <div class="p-mb-2 skeletonEffect"></div>
            <div class="p-mb-2 skeletonEffect"></div>
            <div class="p-mb-2 skeletonEffect"></div>
            <div class="p-mb-2 skeletonEffect"></div>
            <div class="p-mb-2 skeletonEffect"></div>
            <div class="p-mb-2 skeletonEffect"></div>
            <div class="p-mb-2 skeletonEffect"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
