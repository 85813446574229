<div class="main-body lists">
  <!-- list top -->
  <div class="custom-card">
    <div class="profile-card">
      <div class="emp-top">
        <div class="card-title">
          <div class="vts-tag-color panel-title">
            {{ ls.data.activities.activity }}
          </div>
        </div>
      </div>
      <div class="emp-bottom">
        <div class="custom-tabs">
          <ul class="vts-border-theme list-nav">
            <li *ngFor="let item of items"
                (click)="onItemClick(item)"
                [ngClass]="{ 'vts-theme-bg active': item.isActive }"
            >
              <img [src]="item.imgPath"
                   [alt]="item.name"
                   [ngClass]="{ 'vts-svg-primary': !item.isActive }"
              >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- Recent -->
  <ng-container *ngIf="activeItem && activeItem.name === 'Recent' && activeItem.isActive">
    <div class="custom-card mb-0 recent-tab result-body"
         *ngIf="!globalHelper.listData.cndDataLoader && globalHelper.listData.data && globalHelper.listData.data.length"
    >
      <div class="profile-card">
        <div class="empProfile-bottom">
          <div class="viewEmpDetails">
            <div class="emp-details-list recent-list-body"
                 (scroll)="onPageScroll($event)"
            >
              <div *ngFor="let employee of globalHelper.listData.data;let i = index"
                   class="emp-list-item"
              >
                <!-- contact -->
                <ng-container *ngIf="employee.type === 'contact'">
                  <div class="emp-list-top">
                    <div class="emp-list-top-left">
                      <div class="employee-logo">
                        <ng-container *ngIf="employee.imageUrl; else initials">
                          <img [src]="employee.imageUrl"
                               alt="contact-logo"
                          >
                        </ng-container>
                        <ng-template #initials>
                          <div class="initials"
                               [ngStyle]="employee.avatarStyle"
                          >
                            {{ globalHelper.getInitials(employee.firstname + ' ' + employee.lastname) }}
                          </div>
                        </ng-template>
                      </div>
                      <div class="employee-info">
                        <div class="employee-title">
                          <h4 #fullName
                              class="vts-icon-color overflow-text"
                              [tooltip]="fullName.offsetWidth < fullName.scrollWidth ?
                                  employee.firstname + ' ' + employee.lastname : ''"
                              placement="bottom"
                              (click)="goToDetails(employee.id,'contact')"
                          >
                            {{ employee.firstname + ' ' + employee.lastname }}
                          </h4>
                          <ng-container *ngIf="employee?.contact_li_url">
                            <img class="social_icon"
                                 src="assets/images/svg/linkedin.svg"
                                 alt="linkedin"
                                 (click)="goToExternalLink(employee.contact_li_url,false)"
                            >
                          </ng-container>
                          <ng-container *ngIf="employee?.facebook_url">
                            <img class="social_icon"
                                 src="assets/images/svg/facebook.svg" alt="facebook"
                                 (click)="goToExternalLink(employee.facebook_url,false)"
                            >
                          </ng-container>
                        </div>
                        <p #jobTitleEle
                           class="overflow-text"
                           [tooltip]="jobTitleEle.offsetWidth < jobTitleEle.scrollWidth ? employee.job_title :''"
                           placement="bottom"
                        >
                          {{ employee.job_title ? employee.job_title : "" }}
                        </p>
                      </div>
                    </div>
                    <div class="emp-list-top-right">
                      <div class="action">
                        <div class="addList">
                          <img class="vts-svg-primary cursor"
                               [src]="!employee.alreadySaved ? 'assets/images/svg/addList.svg' :
                                  'assets/images/svg/addList-fill.svg'"
                               alt="addList"
                               (click)="getCntList(employee.id,i)"
                               [tooltip]="ls.data.company.toolTip.addToList"
                               placement="top"
                          >
                          <div class="vts-border-theme save-contact"
                               *ngIf="isCntListAdded[i]"
                          >
                            <div class="form-group">
                              <ng-select class="vts-border-theme" #cntListSelect
                                         [items]="globalHelper.cntListData.cntListDataAvl" bindLabel="value"
                                         bindValue="value"
                                         [addTag]="globalHelper.addList"
                                         [addTagText]="ls.data.company.createNewList"
                                         [multiple]="true"
                                         [placeholder]="ls.data.company.placeholder.searchCreateNewList"
                                         [(ngModel)]="globalHelper.selectedListType.selectedCntList"
                                         (change)="globalHelper.onListChange('contact')"
                              ></ng-select>
                            </div>
                            <div class="actionButtons">
                              <span class="vts-theme-bg vts-border-theme fill-btn"
                                    (click)="saveContactList(globalHelper.selectedListType.selectedCntList,i,employee.id)"
                              >
                                {{ ls.data.global.buttons.save }}
                              </span>
                              <span class="vts-border-theme vts-icon-color"
                                    (click)="onListCancel('contact',i)"
                              >
                                {{ ls.data.global.buttons.cancel }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="emp-list-bottom">
                    <div class="emp-list-bottom-left">
                      <div class="vts-green-color-border empDetails">
                        <div class="contact-details"
                             [ngClass]="{
                            'green-contact': employee.alreadyView && (employee.emailAddress || employee.phoneOffice || employee.phoneDirect),
                            'not-available': !employee.emailAddress && !employee.phoneOffice && !employee.phoneDirect,
                            'disabled': employee.isContactCollapse
                         }"
                             (click)="handleContactView(employee.id,employee.isContactCollapse)"
                             #item
                             (mouseenter)="globalHelper.showHover(item)"
                             (mouseleave)="globalHelper.hideHover(item)"
                        >
                          <div class="details-wrapper">
                            <img src="assets/images/svg/xd-email.svg"
                                 alt="mail"
                                 class="vts-svg-primary"
                                 placement="top"
                                 [tooltip]="employee.emailAddress || employee.phoneOffice || employee.phoneDirect ?
                                    ls.data.company.workEmail : ''"
                                 [ngClass]="{
                                     'item-not-available': !employee.emailAddress,
                                     'item-green': employee.alreadyView && employee.emailAddress
                                 }"
                            >
                            <span class="divider">|</span>
                            <img src="assets/images/svg/xd-office-phone.svg"
                                 alt="mail"
                                 class="vts-svg-primary"
                                 placement="top"
                                 [tooltip]="employee.emailAddress || employee.phoneOffice || employee.phoneDirect ?
                                   ls.data.company.phoneOffice : ''"
                                 [ngClass]="{
                                     'item-not-available': !employee.phoneOffice,
                                     'item-green': employee.alreadyView && employee.phoneOffice
                                 }"
                            >
                            <span class="divider">|</span>
                            <img src="assets/images/svg/xd-cell-phone.svg"
                                 alt="mail"
                                 class="vts-svg-primary"
                                 placement="top"
                                 [tooltip]="employee.emailAddress || employee.phoneOffice || employee.phoneDirect ?
                                    ls.data.company.directPhone : ''"
                                 [ngClass]="{
                                 'item-not-available': !employee.phoneDirect,
                                 'item-green': employee.alreadyView && employee.phoneDirect
                                 }"
                            >
                          </div>

                          <div *ngIf="globalHelper.hoveredItem === item &&
                                    (employee.emailAddress || employee.phoneOffice || employee.phoneDirect) &&
                                    !employee.alreadyView"
                               class="show-details"
                               [tooltip]="!employee.alreadyView ? ls.data.global.toolTip.thisCostOneCredit : ''"
                               placement="right"
                          >
                            <p>{{ ls.data.actions.showDetails }}</p>
                          </div>
                        </div>
                        <div class="hide-link"
                             *ngIf="employee.isContactCollapse"
                             (click)="handleContactView(employee.id,employee.isContactCollapse)"
                        >
                          {{ ls.data.actions.hide }}
                        </div>
                      </div>
                      <div class="empDetailsView" *ngIf="employee.isContactCollapse && contactData">
                        <ul>
                          <li [ngClass]="{'na':!contactData.emailAddress}">
                            <div class="label">
                              {{ ls.data.company.workEmail }}
                            </div>
                            <div #emailEle
                                 class="value"
                                 [tooltip]="emailEle.offsetWidth < emailEle.scrollWidth ? contactData.emailAddress :''"
                                 placement="bottom"
                            >
                              :
                              {{
                                contactData.emailAddress ? contactData.emailAddress : "Not Available"
                              }}
                            </div>
                          </li>
                          <li [ngClass]="{'na':!contactData.phoneOffice}">
                            <div class="label">
                              {{ ls.data.company.phoneOffice }}
                            </div>
                            <div class="value">
                              :
                              {{
                                contactData.phoneOffice ? contactData.phoneOffice : "Not Available"
                              }}
                            </div>
                          </li>
                          <li [ngClass]="{'na':!contactData.directPhone}">
                            <div class="label">
                              {{ ls.data.company.directPhone }}
                            </div>
                            <div class="value">
                              :
                              {{
                                contactData.phoneDirect ? contactData.phoneDirect : "Not Available"
                              }}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <!-- company -->
                <ng-container *ngIf="employee.type === 'company'">
                  <div class="emp-list-top">
                    <div class="emp-list-top-left">
                      <div class="employee-logo">
                        <img *ngIf="employee.imageUrl"
                             [src]="employee.imageUrl"
                             alt="company-logo"
                        >
                        <img *ngIf="!employee.imageUrl"
                             src="assets/images/png/defaultCompany.png"
                             alt="default-image"
                             style="background-color: #e7e7e7 !important;"
                        >
                      </div>
                      <div class="employee-info">
                        <div class="employee-title">
                          <h4 #companyName
                              class="vts-icon-color overflow-text"
                              [tooltip]="companyName.offsetWidth < companyName.scrollWidth ? employee.company_name : ''"
                              placement="bottom"
                              (click)="goToDetails(employee.companyid,'company')"
                          >
                            {{ employee.company_name ? employee.company_name : '' }}
                          </h4>
                          <ng-container *ngIf="employee?.company_li_url">
                            <img src="assets/images/svg/linkedin.svg"
                                 alt="linkedin"
                                 class="social_icon"
                                 (click)="goToExternalLink(employee.company_li_url,false)"
                            >
                          </ng-container>
                          <ng-container *ngIf="employee?.company_facebook_url">
                            <img src="assets/images/svg/facebook.svg"
                                 alt="facebook"
                                 class="social_icon"
                                 (click)="goToExternalLink(employee.company_facebook_url,false)"
                            >
                          </ng-container>
                        </div>
                        <p #parentDept
                           class="overflow-text"
                           [tooltip]="parentDept.offsetWidth < parentDept.scrollWidth ? employee.parentdept :''"
                           placement="bottom"
                        >
                          {{ employee.parentdept ? employee.parentdept : "" }}
                        </p>
                      </div>
                    </div>
                    <div class="emp-list-top-right">
                      <div class="action">
                        <div class="addList">
                          <img class="vts-svg-primary cursor"
                               [src]="!employee.alreadySaved ? 'assets/images/svg/addList.svg' :
                                  'assets/images/svg/addList-fill.svg'"
                               alt="addList"
                               (click)="getCmpList(employee.companyid, i)"
                               [tooltip]="ls.data.company.toolTip.addToList"
                               placement="top"
                          >
                          <div class="vts-border-theme save-contact" *ngIf="isCmpListAdded[i]">
                            <div class="form-group">
                              <ng-select #cmpListSelect
                                         [items]="globalHelper.cmpListData.cmpListDataAvl"
                                         bindLabel="value"
                                         bindValue="value"
                                         [addTag]="globalHelper.addList"
                                         [addTagText]="ls.data.company.createNewList"
                                         [multiple]="true"
                                         [placeholder]="ls.data.company.placeholder.searchCreateNewList"
                                         [(ngModel)]="globalHelper.selectedListType.selectedCmpList"
                                         (change)="globalHelper.onListChange('company')"
                              ></ng-select>
                            </div>
                            <div class="actionButtons">
                              <span class="vts-theme-bg vts-border-theme fill-btn"
                                    (click)="saveCompanyList(globalHelper.selectedListType.selectedCmpList,i,employee.companyid)"
                              >
                                {{ ls.data.global.buttons.save }}
                              </span>
                              <span class="vts-border-theme vts-icon-color"
                                    (click)="onListCancel('company',i)"
                              >
                                {{ ls.data.global.buttons.cancel }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="emp-list-bottom">
                    <div class="emp-list-bottom-left">
                      <div class="vts-green-color-border empDetails company">
                        <ul [ngClass]="{'viewed': employee.alreadyView}">
                          <li #hqLocEle
                              [tooltip]="hqLocEle.offsetWidth < hqLocEle.scrollWidth ? employee.hq_location : ''"
                              placement="top"
                          >
                            {{ ls.data.company.hqPrefix + employee.hq_location }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Contact -->
  <ng-container *ngIf="activeItem && activeItem.name === 'Contact' && activeItem.isActive">
    <div class="custom-card mb-0 contact-tab result-body"
         *ngIf="!globalHelper.listData.cndDataLoader && globalHelper.listData.cntData && globalHelper.listData.cntData.length"
    >
      <div class="profile-card">
        <div class="empProfile-bottom">
          <div class="card-title mb-0">
            <div class="vts-border-theme action action-list">
              <div class="vts-border-theme save-contact">
                <div class="form-group">
                  <ng-select #cntDefaultListSelect
                             class="vts-border-theme"
                             [items]="globalHelper.defaultListData.cntListData"
                             bindLabel="label"
                             bindValue="label"
                             [addTag]="globalHelper.addList"
                             [addTagText]="ls.data.company.createNewList"
                             [multiple]="false"
                             [placeholder]="ls.data.settings.defaultList"
                             [(ngModel)]="globalHelper.defaultList.defaultSelectedCntList"
                             (change)="onDefaultListChange($event, 'contact')"
                  ></ng-select>
                </div>
              </div>
            </div>
            <div class="vts-icon-color link"
                 (click)="goToExternalLink(environment.PRE_URL+'/auth/login?loaded=1&returnUrl=/list',true)"
            >
              {{ ls.data.employee.viewMore }}
              <img class="vts-svg-primary"
                   src="assets/images/svg/redirect.svg"
                   alt="redirect"
              >
            </div>
          </div>
          <div class="viewEmpDetails">
            <div class="emp-details-list contact-list-body"
                 (scroll)="onPageScroll($event)"
            >
              <div *ngFor="let employee of globalHelper.listData.cntData;let i = index"
                   class="emp-list-item"
              >
                <div class="emp-list-top">
                  <div class="emp-list-top-left">
                    <div class="employee-logo">
                      <ng-container *ngIf="employee.imageUrl; else initials">
                        <img [src]="employee.imageUrl"
                             alt="company-logo"
                        >
                      </ng-container>
                      <ng-template #initials>
                        <div class="initials"
                             [ngStyle]="employee.avatarStyle"
                        >
                          {{ globalHelper.getInitials(employee.firstname + ' ' + employee.lastname) }}
                        </div>
                      </ng-template>
                    </div>
                    <div class="employee-info">
                      <div class="employee-title">
                        <h4 #fullName2
                            class="vts-icon-color overflow-text"
                            [tooltip]="fullName2.offsetWidth < fullName2.scrollWidth ?
                                employee.firstname + ' ' + employee.lastname : ''"
                            placement="bottom"
                            (click)="goToDetails(employee.id,'contact')"
                        >
                          {{ employee.firstname + ' ' + employee.lastname }}
                        </h4>
                        <ng-container *ngIf="employee?.contact_li_url">
                          <img src="assets/images/svg/linkedin.svg"
                               alt="linkedin"
                               class="social_icon"
                               (click)="goToExternalLink(employee.contact_li_url,false)"
                          >
                        </ng-container>
                        <ng-container *ngIf="employee?.facebook_url">
                          <img src="assets/images/svg/facebook.svg"
                               alt="facebook"
                               class="social_icon"
                               (click)="goToExternalLink(employee.facebook_url,false)"
                          >
                        </ng-container>
                      </div>
                      <p #jobTitleEle2
                         class="overflow-text"
                         [tooltip]="jobTitleEle2.offsetWidth < jobTitleEle2.scrollWidth ? employee.job_title :''"
                         placement="bottom"
                      >
                        {{ employee.job_title ? employee.job_title : "" }}
                      </p>
                    </div>
                  </div>
                  <div class="emp-list-top-right">
                    <div class="action">
                      <div class="addList">
                        <img class="vts-svg-primary cursor"
                             [src]="!employee.alreadySaved ? 'assets/images/svg/addList.svg' : 'assets/images/svg/addList-fill.svg'"
                             alt="addList"
                             (click)="getCntList(employee.id,i)"
                             [tooltip]="ls.data.company.toolTip.addToList"
                             placement="top"
                        >
                        <div class="vts-border-theme save-contact" *ngIf="isCntListAdded[i]">
                          <div class="form-group">
                            <ng-select class="vts-border-theme"
                                       [items]="globalHelper.cntListData.cntListDataAvl"
                                       bindLabel="value"
                                       bindValue="value"
                                       [addTag]="globalHelper.addList"
                                       [addTagText]="ls.data.company.createNewList"
                                       [multiple]="true"
                                       [placeholder]="ls.data.company.placeholder.searchCreateNewList"
                                       [(ngModel)]="globalHelper.selectedListType.selectedCntList"
                                       (change)="globalHelper.onListChange('contact')"
                            ></ng-select>
                          </div>
                          <div class="actionButtons">
                            <span class="vts-theme-bg vts-border-theme fill-btn"
                                  (click)="saveContactList(globalHelper.selectedListType.selectedCntList,i,employee.id)"
                            >
                              {{ ls.data.global.buttons.save }}
                            </span>
                            <span class="vts-border-theme vts-icon-color"
                                  (click)="onListCancel('contact',i)"
                            >
                              {{ ls.data.global.buttons.cancel }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="emp-list-bottom">
                  <div class="emp-list-bottom-left">
                    <div class="vts-green-color-border empDetails">
                      <div class="contact-details"
                           [ngClass]="{
                            'green-contact': employee.alreadyView && (employee.emailAddress || employee.phoneOffice || employee.phoneDirect),
                            'not-available': !employee.emailAddress && !employee.phoneOffice && !employee.phoneDirect,
                            'disabled': employee.isContactCollapse
                         }"
                           (click)="handleContactView(employee.id,employee.isContactCollapse)"
                           #item
                           (mouseenter)="globalHelper.showHover(item)"
                           (mouseleave)="globalHelper.hideHover(item)"
                      >
                        <div class="details-wrapper">
                          <img src="assets/images/svg/xd-email.svg"
                               alt="mail"
                               class="vts-svg-primary"
                               placement="top"
                               [tooltip]="employee.emailAddress || employee.phoneOffice || employee.phoneDirect ?
                                    ls.data.company.workEmail : ''"
                               [ngClass]="{
                                     'item-not-available': !employee.emailAddress,
                                     'item-green': employee.alreadyView && employee.emailAddress
                                 }"
                          >
                          <span class="divider">|</span>
                          <img src="assets/images/svg/xd-office-phone.svg"
                               alt="mail"
                               class="vts-svg-primary"
                               placement="top"
                               [tooltip]="employee.emailAddress || employee.phoneOffice || employee.phoneDirect ?
                                   ls.data.company.phoneOffice : ''"
                               [ngClass]="{
                                     'item-not-available': !employee.phoneOffice,
                                     'item-green': employee.alreadyView && employee.phoneOffice
                                 }"
                          >
                          <span class="divider">|</span>
                          <img src="assets/images/svg/xd-cell-phone.svg"
                               alt="mail"
                               class="vts-svg-primary"
                               placement="top"
                               [tooltip]="employee.emailAddress || employee.phoneOffice || employee.phoneDirect ?
                                    ls.data.company.directPhone : ''"
                               [ngClass]="{
                                 'item-not-available': !employee.phoneDirect,
                                 'item-green': employee.alreadyView && employee.phoneDirect
                                 }"
                          >
                        </div>

                        <div *ngIf="globalHelper.hoveredItem === item &&
                                    (employee.emailAddress || employee.phoneOffice || employee.phoneDirect) &&
                                    !employee.alreadyView"
                             class="show-details"
                             [tooltip]="!employee.alreadyView ? ls.data.global.toolTip.thisCostOneCredit : ''"
                             placement="right"
                        >
                          <p>{{ ls.data.actions.showDetails }}</p>
                        </div>
                      </div>
                      <div class="hide-link"
                           *ngIf="employee.isContactCollapse"
                           (click)="handleContactView(employee.id,employee.isContactCollapse)"
                      >
                        {{ ls.data.actions.hide }}
                      </div>
                    </div>
                    <div class="empDetailsView" *ngIf="employee.isContactCollapse && contactData">
                      <ul>
                        <li [ngClass]="{'na':!contactData.emailAddress}">
                          <div class="label">
                            {{ ls.data.company.workEmail }}
                          </div>
                          <div #emailEle
                               class="value"
                               [tooltip]="emailEle.offsetWidth < emailEle.scrollWidth ? contactData.emailAddress :''"
                               placement="bottom"
                          >
                            :
                            {{
                              contactData.emailAddress ? contactData.emailAddress : "Not Available"
                            }}
                          </div>
                        </li>
                        <li [ngClass]="{'na':!contactData.phoneOffice}">
                          <div class="label">
                            {{ ls.data.company.phoneOffice }}
                          </div>
                          <div class="value">
                            :
                            {{
                              contactData.phoneOffice ? contactData.phoneOffice : "Not Available"
                            }}
                          </div>
                        </li>
                        <li [ngClass]="{'na':!contactData.directPhone}">
                          <div class="label">
                            {{ ls.data.company.directPhone }}
                          </div>
                          <div class="value">
                            :
                            {{
                              contactData.phoneDirect ? contactData.phoneDirect : "Not Available"
                            }}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Company -->
  <ng-container *ngIf="activeItem && activeItem.name === 'Company' && activeItem.isActive">
    <div class="custom-card mb-0 company-tab result-body"
         *ngIf="!globalHelper.listData.cndDataLoader && globalHelper.listData.cmpData && globalHelper.listData.cmpData.length"
    >
      <div class="profile-card">
        <div class="empProfile-bottom">
          <div class="card-title mb-0">
            <div class="action action-list">
              <div class="vts-border-theme save-contact">
                <div class="form-group">
                  <ng-select class="vts-border-theme" #cmpDefaultListSelect
                             [items]="globalHelper.defaultListData.cmpListData"
                             bindLabel="label"
                             bindValue="label"
                             [addTag]="globalHelper.addList"
                             [addTagText]="ls.data.company.createNewList"
                             [multiple]="false"
                             [placeholder]="ls.data.settings.defaultList"
                             [(ngModel)]="globalHelper.defaultList.defaultSelectedCmpList"
                             (change)="onDefaultListChange($event, 'company')"
                  ></ng-select>
                </div>
              </div>
            </div>
            <div class="vts-icon-color link"
                 (click)="goToExternalLink(environment.PRE_URL+'/auth/login?loaded=1&returnUrl=/list',true)"
            >
              {{ ls.data.employee.viewMore }}
              <img class="vts-svg-primary"
                   src="assets/images/svg/redirect.svg"
                   alt="redirect"
              >
            </div>
          </div>
          <div class="viewEmpDetails">
            <div class="emp-details-list company-list-body"
                 (scroll)="onPageScroll($event)"
            >
              <div *ngFor="let employee of globalHelper.listData.cmpData;let i = index"
                   class="emp-list-item"
              >
                <div class="emp-list-top">
                  <div class="emp-list-top-left">
                    <div class="employee-logo">
                      <img *ngIf="employee.imageUrl"
                           [src]="employee.imageUrl"
                           alt="company-logo"
                      >
                      <img *ngIf="!employee.imageUrl"
                           src="assets/images/png/defaultCompany.png"
                           alt="default-image"
                           style="background-color: #e7e7e7 !important;"
                      >
                    </div>
                    <div class="employee-info">
                      <div class="employee-title">
                        <h4 #companyName3
                            class="vts-icon-color overflow-text"
                            [tooltip]="companyName3.offsetWidth < companyName3.scrollWidth ? employee.company_name : ''"
                            placement="bottom"
                            (click)="goToDetails(employee.companyid,'company')"
                        >
                          {{ employee.company_name ? employee.company_name : '' }}
                        </h4>
                        <ng-container *ngIf="employee?.company_li_url">
                          <img src="assets/images/svg/linkedin.svg"
                               alt="linkedin"
                               class="social_icon"
                               (click)="goToExternalLink(employee.company_li_url,false)"
                          >
                        </ng-container>
                        <ng-container *ngIf="employee?.company_facebook_url">
                          <img src="assets/images/svg/facebook.svg" alt="facebook"
                               class="social_icon"
                               (click)="goToExternalLink(employee.company_facebook_url,false)"
                          >
                        </ng-container>
                      </div>
                      <p #parentDept3
                         class="overflow-text"
                         [tooltip]="parentDept3.offsetWidth < parentDept3.scrollWidth ? employee.parentdept :''"
                         placement="bottom"
                      >
                        {{ employee.parentdept ? employee.parentdept : "" }}
                      </p>
                    </div>
                  </div>
                  <div class="emp-list-top-right">
                    <div class="action">
                      <div class="addList">
                        <img class="vts-svg-primary cursor"
                             [src]="!employee.alreadySaved ? 'assets/images/svg/addList.svg' : 'assets/images/svg/addList-fill.svg'"
                             alt="addList"
                             (click)="getCmpList(employee.companyid, i)"
                             [tooltip]="ls.data.company.toolTip.addToList"
                             placement="top"
                        >
                        <div class="vts-border-theme save-contact"
                             *ngIf="isCmpListAdded[i]"
                        >
                          <div class="form-group">
                            <ng-select [items]="globalHelper.cmpListData.cmpListDataAvl"
                                       bindLabel="value"
                                       bindValue="value"
                                       [addTag]="globalHelper.addList"
                                       [addTagText]="ls.data.company.createNewList"
                                       [multiple]="true"
                                       [placeholder]="ls.data.company.placeholder.searchCreateNewList"
                                       [(ngModel)]="globalHelper.selectedListType.selectedCmpList"
                                       (change)="globalHelper.onListChange('company')"
                            ></ng-select>
                          </div>
                          <div class="actionButtons">
                            <span class="vts-theme-bg vts-border-theme fill-btn"
                                  (click)="saveCompanyList(globalHelper.selectedListType.selectedCmpList,i,employee.companyid)"
                            >
                              {{ ls.data.global.buttons.save }}
                            </span>
                            <span class="vts-border-theme vts-icon-color"
                                  (click)="onListCancel('company',i)"
                            >
                              {{ ls.data.global.buttons.cancel }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="emp-list-bottom">
                  <div class="emp-list-bottom-left">
                    <div class="vts-green-color-border empDetails company">
                      <ul [ngClass]="{'viewed': employee.alreadyView}">
                        <li #hqLoc
                            [tooltip]="hqLoc.offsetWidth < hqLoc.scrollWidth ? employee.hq_location : ''"
                            placement="top"
                        >
                          {{ ls.data.company.hqPrefix + employee.hq_location }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- No Data Found -->
  <ng-container *ngIf="!globalHelper.listData.cndDataLoader && !globalHelper.listData.data.length">
    <div class="main-body settings">
      <div class="custom-card">
        <h1 class="coming-soon">
          {{ ls.data.employee.noDataFound }}
        </h1>
      </div>
    </div>
  </ng-container>

  <!-- Skeleton -->
  <ng-container *ngIf="globalHelper.listData.cndDataLoader">
    <div class="container p-0">
      <div class="row">
        <div class="skeleton">
          <div class="custom-skeleton">
            <div class="stripes fullEmpStripes">
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <br>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <br>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <br>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <br>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <br>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <br>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
