export interface IProfileData {
    fullName: string;
    job_title: string;
    contactLiUrl: string;
    company_name: string;
    companyId: string;
    work_email: string;
    personal_email: string;
    phone_office: string;
    phone_direct: string;
    id: string;
    contactId: string;
    initialsOfName: string;
    parentDept: string;
    emp_range: string;
    rev_range: string;
    hq_location: string;
    company_li_url: string;
    company_facebook_url: string;
    company_twitter_url: string;
    cmpLogo: string;
    isSubExpire: string;
    website: string;
    founded: string;
    company_description: string;
    specialty: string[];
    facebook_url: string;
    imageUrl: string;
    avatarStyle: any;
}

export interface IToastMessage {
    visibility: boolean;
    message: string;
    type: boolean;
}

export interface IDefaultList {
    defaultSelectedCntList: any;
    defaultSelectedCmpList: any;
}

export interface IIsExpanded {
    open: boolean;
}

export interface IPluginSettings {
    open: boolean;
    position: string;
    everywhere: boolean;
    askWhereToSave: boolean;
    onload: string;
    clicked: string;
    minimize: string;
    theme: string;
}

export interface IDefaultListData {
    cntListData: [];
    cmpListData: [];
}

export interface IContactListData {
    cntListDataAvl: [];
    cntListDataNotAvl: [];
}

export interface ICompanyListData {
    cmpListDataAvl: [];
    cmpListDataNotAvl: [];
}

export interface IIsSaved {
    isCntSaved: boolean;
    isCmpSaved: boolean;
}

export interface ISelectedListType {
    selectedCntList: any;
    selectedCmpList: any;
}

export interface IEmployeeData {
    cntData: {
        fullName: string;
        initials: string;
        jobTitle: string;
        contactId: string;
        companyId: string;
        alreadyView: boolean;
        isContactCollapse: boolean;
        contact_li_url: string;
        alreadySaved: boolean;
        contactLogoId: string;
        facebook_url: string;
        imageUrl: string;
        avatarStyle: any;
        emailAddress: boolean;
        phoneDirect: boolean;
        phoneOffice: boolean;
        revealContact: boolean;
    }[];
    cndDataLoader: boolean;
}

export interface ICompany {
    company_li_url: string;
    hq_location: string;
    website: string;
    company_name: string;
    parentdept: string;
    companyid: string;
    alreadySaved: boolean;
    alreadyView: boolean;
    type: 'company';
    company_facebook_url: string;
    imageUrl: string;
    avatarStyle: any;
    revealContact: boolean;
    companyLogoId: string;
}

export interface IContact {
    id: string;
    fullName: string;
    firstname: string;
    lastname: string;
    job_title: string;
    contact_li_url: string;
    isContactCollapse: boolean;
    alreadyView: boolean;
    alreadySaved: boolean;
    type: 'contact';
    facebook_url: string;
    imageUrl: string;
    avatarStyle: any;
    emailAddress: boolean;
    phoneDirect: boolean;
    phoneOffice: boolean;
    revealContact: boolean;
}

export type IDataItem = ICompany | IContact;

export interface IListData {
    data: IDataItem[];
    cntData: IContact[];
    cmpData: ICompany[];
    enabledScroll: boolean;
    cndDataLoader: boolean;
}

export interface IChromeExtCredits {
    chExtCredits: any;
    usedChromeExtCredits: any;
    progress: number;
    tier: any;
}

export interface IPhoneNumber {
    phone: number;
    countryCode: number;
    fullNumber: string;
    country: string;
    countryShort: string;
}

export interface IUserProfile {
    id: string;
    jobTitle: string;
    isLocked: boolean;
    isDeleted: boolean;
    userId: string;
    accountId: string;
    firstName: string;
    lastName: string;
    emailId: string;
    phoneNumber: IPhoneNumber;
    city: number;
    country: number;
    linkedinUrl: string;
    state: number;
    userRole: string;
    countryName: string;
    stateName: string;
    companyName: string;
}

export interface ISubscription {
    customerId: string;
    accountId: string;
    subsId: string;
    name: string;
    type: string;
    tier: string;
    totalUsers: number;
    totalDownloads: number;
    totalViews: number;
    subCost: string;
    usedDownloads: number;
    usedViews: number;
    expiryDate: string;
    isExpired: boolean;
    isActive: boolean;
    createdDate: string;
    usedUsers: number;
    remUsers: number;
    remDownloads: number;
    remViews: number;
    subsFeatures: any[];
    expiredIn: number;
    tierName: string;
    invoiceUrl: string;
    startDate: string;
    invoicePdfUrl: string;
    billingUrl: string;
    accountCreationDate: string;
    addons: any[];
}

export interface IContactScrappedData {
    contactName: string;
    contactImageUrl: string;
    contactJobDesc: string;
    contactLocation: string;
    contactLiUrl: string;
    contactEmail: string;
    contactPhone: string;
    contactConnections: string;
    experience: IScrappedExperienceData[];
    education: IScrappedEducationData[];
    skills: IScrappedSkillsData[];
    recommendations: IScrappedRecommendationsData[];
}

export interface ICompanyScrappedData {
    companyName: string;
    companyLogoUrl: string;
    companyLiUrl: string;
    companyLocation: string;
    companyFollowers: string;
    companyOverview: string;
    companyWebsite: string;
    companyIndustry: string;
    companySize: string;
    companyHeadquarters: string;
    companyFounded: string;
    companySpecialties: string[];
    companyJobPosts: IScrappedJobPostsData[];
    companyPosts: IScrappedPostsData[];
}

export interface IScrappedJobPostsData {
    jobPostTitle: string;
    jobPostCompany: string;
    jobPostLocation: string;
    jobPostOn: string;
}

export interface IScrappedPostsData {
    postedOn: string;
    postTitle: string;
    likes: string;
    comments: string;
    reposts: string;
}

export interface IScrappedExperienceData {
    companyName: string;
    companyLogoUrl: string;
    companyLiUrl: string;
    jobTitle: string;
    jobDescription: string;
    workLocation: string;
    workDuration: string;
}

export interface IScrappedEducationData {
    university: string;
    degree: string;
    dateOfAttendance: string;
}

export interface IScrappedSkillsData {
    skill: string;
    endorsements: string;
}

export interface IScrappedRecommendationsData {
    type: string;
    name: string;
    jobTitle: string;
}

export interface ILinkedInScrappedData {
    contact: IContactScrappedData;
    company: ICompanyScrappedData;
    tabUrl: string;
    urlType: string;
    linkedinHTML: any;
}

export const emptyIsExpanded: IIsExpanded = {
    open: false,
};

export const emptyIsSaved: IIsSaved = {
    isCntSaved: false,
    isCmpSaved: false,
};

export const emptyIListData: IListData = {
    data: [],
    cntData: [],
    cmpData: [],
    enabledScroll: true,
    cndDataLoader: true,
};

export const emptyIProfileData: IProfileData = {
    fullName: '',
    job_title: '',
    contactLiUrl: '',
    company_name: '',
    companyId: '',
    work_email: '',
    personal_email: '',
    phone_office: '',
    phone_direct: '',
    id: '',
    contactId: '',
    initialsOfName: '',
    parentDept: '',
    emp_range: '',
    rev_range: '',
    hq_location: '',
    company_li_url: '',
    company_facebook_url: '',
    company_twitter_url: '',
    cmpLogo: '',
    isSubExpire: '',
    website: '',
    founded: '',
    company_description: '',
    specialty: [],
    facebook_url: '',
    imageUrl: '',
    avatarStyle: '',
};

export const emptyIDefaultList: IDefaultList = {
    defaultSelectedCntList: undefined,
    defaultSelectedCmpList: undefined,
};

export const emptyIEmployeeData: IEmployeeData = {
    cntData: [],
    cndDataLoader: false,
};

export const emptyIDefaultListData: IDefaultListData = {
    cntListData: [],
    cmpListData: [],
};

export const emptyICompanyListData: IContactListData = {
    cntListDataAvl: [],
    cntListDataNotAvl: [],
};

export const emptyIContactListData: IContactListData = {
    cntListDataAvl: [],
    cntListDataNotAvl: [],
};

export const emptyIPluginSettings: IPluginSettings = {
    open: false,
    position: 'right',
    everywhere: false,
    askWhereToSave: false,
    onload: 'onload',
    clicked: '',
    minimize: '',
    theme: 'blue-theme',
};

export const emptyIToastMessage: IToastMessage = {
    visibility: false,
    message: '',
    type: false,
};

export const emptyISelectedListType: ISelectedListType = {
    selectedCntList: [],
    selectedCmpList: [],
};

export const emptyLinkedInScrappedData: ILinkedInScrappedData = {
    contact: {
        contactName: '',
        contactImageUrl: '',
        contactJobDesc: '',
        contactLocation: '',
        contactLiUrl: '',
        contactEmail: '',
        contactPhone: '',
        contactConnections: '',
        experience: [],
        education: [],
        skills: [],
        recommendations: [],
    },
    company: {
        companyName: '',
        companyLogoUrl: '',
        companyLiUrl: '',
        companyLocation: '',
        companyFollowers: '',
        companyOverview: '',
        companyWebsite: '',
        companyIndustry: '',
        companySize: '',
        companyHeadquarters: '',
        companyFounded: '',
        companySpecialties: [],
        companyJobPosts: [],
        companyPosts: [],
    },
    urlType: '',
    tabUrl: '',
    linkedinHTML: '',
};
